import React from "react"
import { SectionLayout } from "../components/Layout"
import { useStaticQuery, graphql } from "gatsby"
import {LetterHop} from "../components/Animations"

const Intro = () => {
  const data = useStaticQuery(graphql`
    query IntroQuery {
      markdownRemark(frontmatter: {page: {eq: "intro"}}) {
        frontmatter {
          statement_one
          greeting
        }
      }
    }
  `)

  const introData = data.markdownRemark.frontmatter

  const splitTitle = introData.greeting
    .split("")
    .map((char, i) => <h1 key={i} className="intro-greeting">{char}</h1>)
  return (
    <div className="intro-section">
      <SectionLayout>
        <div className="intro-wrapper">
          <div className="intro-text-wrapper">
            <div className="intro-wrapper"><LetterHop>{splitTitle}</LetterHop></div>
            <h1 className="intro-statement">{introData.statement_one}</h1>
          </div>
        </div>
      </SectionLayout>
    </div>
  )
}

export default Intro
