import { useSpring, animated, useTrail, a } from "react-spring"
import React, { useState } from "react"
import { config } from "@fortawesome/fontawesome-svg-core"

const RotatingBlock = () => {
  const styles = useSpring({
    loop: true,
    from: { rotateZ: 0 },
    to: { rotateZ: 180 },
  })
  return (
    <animated.div
      style={{
        width: 80,
        height: 80,
        backgroundColor: "#46E891",
        borderRadius: 16,
        ...styles,
      }}
    />
  )
}

// useTrail
const LetterHop = ({ children }) => {
  const items = React.Children.toArray(children)
  const trail = useTrail(items.length, {
    loop: { reverse: true },
    from: { y: 0 },
    to: { y: -15 },
  })
  return trail.map(({ ...style }, index) => (
    <a.div key={index} style={style}>
      <a.div>{items[index]}</a.div>
    </a.div>
  ))
}
// helper function to animate individual letters
const ShiftLetter = props => {
  const { length, letter } = props
  const styles = useSpring({
    loop: { reverse: true },
    from: { y: 0 },
    to: { y: length },
  })
  return (
    <div>
      <animated.div
        style={{
          ...styles,
        }}
      >
        {letter}
      </animated.div>
    </div>
  )
}

const RainbowLetters = () => {
  let letters = ["h", "e", "l", "l", "o"]

  const rainbowLetters = letters.map((letter, i) => (
    <ShiftLetter letter={letter} length={-15} key={i} />
  ))
  return (
    <div style={{ display: "flex" }}>
      <LetterHop>
        <p>h</p>
        <p>e</p>
        <p>l</p>
        <p>l</p>
        <p>o</p>
      </LetterHop>
    </div>
  )
}

const FadingText = () => {
  const [flip, set] = useState(false)

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    reset: true,
    reverse: flip,
    delay: 200,
    config: config.molasses,
    onRest: () => set(!flip),
  })
  return <animated.h1 style={props}>hello</animated.h1>
}

export { RotatingBlock, FadingText, RainbowLetters, LetterHop }
